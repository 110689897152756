<template>
  <div>
    <b-modal
        id="editarUsuario"
        hide-footer
        centered
        size="md"
        @shown="abriuModalEditarUsuario"
        title="Editar Usuário"
    >
      <b-card>

        <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
          <div class="alert-body my-2" v-for="(error, index) in getErrors.messageArray" :key="index">
            {{ error[0] }}
          </div>
          <div class="alert-body">{{ getErrors.message }}</div>
        </b-alert>

        <validation-observer ref="editarUsuarioValidation">
          <b-form class="mt-2">
            <b-row>

              <!--          NOME-->
              <b-col sm="12">
                <b-form-group  label="Nome"  label-for="name" >
                  <validation-provider #default="{ errors }" name="Nome" rules="required">
                    <b-form-input id="name" v-model="form.name" name="name" placeholder="Nome do Usuário"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


              <!--          EMAIL-->
              <b-col sm="12">
                <b-form-group  label="E-mail"  label-for="account-e-mail" >
                  <validation-provider #default="{ errors }" name="Email" rules="required|email">
                    <b-form-input id="account-e-mail" v-model.trim="form.email" name="email" placeholder="Email"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--              SENHA-->
              <b-col sm="12">
                <b-form-group label="Nova Senha" label-for="senha">
                  <validation-provider #default="{ errors }" name="Senha" rules="password|min:8|max:255">
                    <b-form-input id="senha" v-model="senha" name="senha" placeholder="Nova Senha"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--              CONFIRMAR SENHA-->
              <b-col sm="12">
                <b-form-group label="Confirmar Senha" label-for="confirmar-senha">
                  <validation-provider #default="{ errors }" name="Confirmar Senha"  :rules="senha ? 'required|confirmed:Senha' : ''" >
                    <b-form-input id="confirmar-senha" v-model="confirmarSenha" name="confirmarSenha" placeholder="Confirmar Senha" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>

      </b-card>

      <b-row align-h="end">
        <b-col cols="auto" class="mb-3">
          <b-button type="submit" class="mt-2 mr-1" variant="primary" :disabled="submit" @click.prevent="editarUsuario">
            <b-spinner small v-if="spinner" />
            <span v-if="spinner">Aguarde...</span>
            <span v-else>Gravar Alterações</span>
          </b-button>
          <b-button  class="mt-2" type="reset" variant="outline-secondary" @click="$bvModal.hide('editarUsuario')">Fechar </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>




</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
  BRow,
  BSpinner,
  BTable
} from 'bootstrap-vue';


import VueAvatar from './src/components/VueAvatar.vue'
import VueAvatarScale from './src/components/VueAvatarScale.vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: ['editar'],
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormSelect,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BTable,
    BSpinner,
    BFormSelectOption,
    VueAvatar,
    VueAvatarScale,
    ValidationProvider,
    ValidationObserver,

  },
  data() {
    return {
      getErrors: {
        hasMessage: false,
        color: 'danger',
        message: '',
        messageArray: ''
      },
      senha: null,
      confirmarSenha: null,
      boxTwo: '',
      errors:[],
      spinner: false,
      submit: false,
      tipo_usuario: [
        { id: '', nome: 'Selecione o Tipo do Usuário'},
        { id: 2, nome: 'Radiologia'},
        { id: 3, nome: 'Projetos CAD'},
        { id: 4, nome: 'Outros'},
      ],
    }
  },
  computed: {
    form: {
      get() {
        return this.editar
      },
      set(new_val) {
        this.$emit('editar', new_val)
      }
    }
  },
  methods: {

    abriuModalEditarUsuario() {
      this.limparErrors()
    },

    limparErrors() {

      this.getErrors.hasMessage = false
      this.getErrors.message =  ''

    },


    editarUsuario(){
      this.$emit('editar', this.form)

      this.$refs.editarUsuarioValidation.validate().then(success => {
        if(success){
          this.spinner = true
          this.submit = true

          this.form.email = this.form.email.toLowerCase();

          const payload = {
            id: this.form.id,
            name: this.form.name,
            email: this.form.email,
            password: this.senha,
          }

          this.$http.put('odontodocs/usuarios/'+this.form.id, payload).then(res => {

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuário alterado com sucesso!',
                icon: 'InfoIcon',
                variant: 'success',
              },
            })

            this.$bvModal.hide('editarUsuario')
            this.$store.dispatch('user/listaUsuarios')

          })
          .catch(error => {
            this.getErrors = this.$responseMessages.getMessage(error, null)
          })
          .finally( () => {
            this.spinner = false
            this.submit = false
          })
        }
      })
    },

  },

}
</script>
