<template>
  <div>
    <b-modal
        id="adicionarUsuario"
        hide-footer
        centered
        size="md"
        @shown="abriuModalAdicionarUsuario"
        title="Adicionar Usuário Externo"
    >
      <b-card>

        <b-alert
            :variant="getErrors.color"
            v-if="getErrors.hasMessage"
            show
        >
          <div class="alert-body my-2" v-for="(error, index) in getErrors.messageArray" :key="index">
            {{ error[0] }}
          </div>
          <div class="alert-body">{{ getErrors.message }}</div>
        </b-alert>

        <validation-observer ref="novoUsuarioValidation">
          <b-form class="mt-2">

            <b-row>

              <b-col sm="12">
                <b-form-group label="Selecione um Dentista Conveniado" label-for="account-tipo">
                  <validation-provider #default="{ errors }" name="Dentista Conveniado" rules="required">
                    <b-form-select
                        v-model="form.conveniado_id"
                        :options="conveniadosSemCadastroUsuario"
                        :state="errors.length > 0 ? false:null"
                        text-field="conveniado_nome"
                        value-field="id"
                        @change="selecionouConveniado"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>

              <b-col sm="12">
                <b-form-group label="Nome"  label-for="account-username" >
                  <validation-provider #default="{ errors }"  name="Nome" rules="required|min:3">
                    <b-form-input  v-model="form.name" :state="errors.length > 0 ? false:null" name="nome"  placeholder="Nome do Usuário" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="12">
                <b-form-group  label="E-mail"  label-for="account-e-mail" >
                  <validation-provider #default="{ errors }"  name="Email" rules="required|email">
                    <b-form-input v-model="form.email"  :state="errors.length > 0 ? false:null" name="email" placeholder="Email"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--              SENHA-->
              <b-col sm="12">
                <b-form-group label="Nova Senha" label-for="senha">
                  <validation-provider #default="{ errors }" name="Senha" rules="required|password|min:8|max:255">
                    <b-form-input id="senha" v-model="form.senha" name="senha" placeholder="Nova Senha"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--              CONFIRMAR SENHA-->
              <b-col sm="12">
                <b-form-group label="Confirmar Senha" label-for="confirmar-senha">
                  <validation-provider #default="{ errors }" name="Confirmar Senha"  rules="required|confirmed:Senha" >
                    <b-form-input id="confirmar-senha" v-model="confirmarSenha" name="confirmarSenha" placeholder="Confirmar Senha" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


            </b-row>
          </b-form>
        </validation-observer>

        <b-alert
            variant="warning"
            show
        >
          <div class="alert-body">
            <span><strong>Obs.:</strong> Dentistas Conveniados só terão acesso a tela de Inicio e Pedidos, será mostrado apenas serviços atribuidos ao dentista conveniado! </span>
          </div>
        </b-alert>

      </b-card>

      <b-row align-h="end">
        <b-col cols="auto" class="mb-3">
          <b-button
              v-if="form.conveniado_id > 0"
              type="submit"
              class="mt-2 mr-1"
              variant="primary"
              :disabled="submit"
              @click.prevent="cadastrarUsuario"
          >
            <b-spinner small v-if="spinner" />
            <span v-if="spinner">Aguarde...</span>
            <span v-else>Cadastrar Usuário</span>
          </b-button>

          <b-button  class="mt-2" type="reset" variant="outline-secondary" @click="$bvModal.hide('adicionarUsuario')">Voltar </b-button>
        </b-col>
      </b-row>
    </b-modal>

  </div>

</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
  BRow,
  BSpinner,
  BTable
} from 'bootstrap-vue';


import VueAvatar from './src/components/VueAvatar.vue'
import VueAvatarScale from './src/components/VueAvatarScale.vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {email, required} from '@validations'
import {mapGetters, mapState} from 'vuex'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormSelect,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BTable,
    BSpinner,
    BFormSelectOption,
    VueAvatar,
    VueAvatarScale,
    ValidationProvider,
    ValidationObserver,

  },
  data() {

    return {
      getErrors: {
        hasMessage: false,
        color: 'danger',
        message: '',
        messageArray: ''
      },
      errors:[],
      spinner: false,
      submit: false,
      confirmarSenha: null,
      form:
          {
            name: '',
            email: '',
            conveniado_id: null,
            tipo_usuario: 2,
            senha: null,
            odonto_user_id: this.$store.state.user.user.id
          },

    }
  },

  computed: {
    ...mapState({
      laboSelecionado: state => state.laboratorios.laboSelecionado
    }),

    ...mapGetters({
      conveniadosSemCadastroUsuario: "user/conveniadosSemCadastroUsuario"
    })

  },
  methods: {

    selecionouConveniado(conveniado_id) {

      let dadosConveniado = this.conveniadosSemCadastroUsuario.find( v => Number(v.id) === Number(conveniado_id) )

      this.form.name = dadosConveniado.conveniado_nome
      this.form.email = dadosConveniado.conveniado_email

    },

    abriuModalAdicionarUsuario() {
      this.$store.dispatch('user/listaConveniados', this.laboSelecionado)
    },

    limparForm() {

      this.form = {
        url: '',
        name: '',
        email: '',
        senha: null,
        conveniado_id: null,
        tipo_usuario: 2
      }
      this.confirmarSenha = null

    },
    cadastrarUsuario(){
      this.$refs.novoUsuarioValidation.validate().then(success => {
        if(success){

          this.spinner = true
          this.submit = true

          const payload = {
            ...this.form,
            email: this.form.email.toLowerCase(),
            password: this.form.senha,
          }

          this.$http.post('odontodocs/usuarios', payload).then(res => {

            this.$emit('cadastro', true)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuário cadastrado com sucesso! um email de confirmação foi enviado',
                icon: 'InfoIcon',
                variant: 'success',
              },
            })


            this.getErrors.color = 'success'
            this.getErrors.hasMessage = true
            this.getErrors.message = `Usuário cadastrado com sucesso, enviamos um email para ${this.form.email}, favor confirmar o email para ativar o Usuário!`

            this.limparForm()
            //   this.$store.dispatch('user/listaUsuarios')
            this.$refs.novoUsuarioValidation.reset();
          })
              .catch(error => {
                this.getErrors = this.$responseMessages.getMessage(error, null)
              })
              .finally( () => {
                this.spinner = false
                this.submit = false
              })
        }

      })
    },

  },

}
</script>
